import mixins from '@/mixins'
import course from '@/mixins/course'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { debounce } from 'lodash'
import moment from 'moment'

export default {
  name: 'courses',
  mixin: [mixins, course],
  data () {
    return {
      page: 1,
      pageCount: 20,
      itemsPerPage: 20,
      showRemoveDialog: false,
      removingItem: null
    }
  },
  filters: {
    formattedDate (value) {
      if (!value) return ''
      return moment(value).format('DD.MM.YYYY HH:mm')
    }
  },
  computed: {
    ...mapGetters({
      list: 'courses/list',
      loading: 'courses/listLoading',
      lengthList: 'courses/listLength',
      skip: 'courses/skip',
      limit: 'courses/limit',
      setting: 'courses/setting',
      categories: 'courses/categories',
      types: 'courses/types',

      filterSearchInput: 'courses/filterSearchInput',
      filterCountries: 'courses/filterCountries',
      filterType: 'courses/filterType',
      filterStatus: 'courses/filterStatus',
      filterStore: 'courses/filterStore',
      profile: 'profile/profile',
      accessCountries: 'profile/accessCountries',
    }),
    storeStatuses () {
      return [
        {
          name: 'All',
          value: ''
        },
        {
          name: 'Visible',
          value: '1'
        },
        {
          name: 'Hidden',
          value: '0'
        }

      ]
    },
    headers () {
      return [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Picture',
          value: 'picture'
        },
        {
          text: 'Created time',
          value: 'createdAt'
        },

        {
          text: 'Status',
          value: 'status',
          sort: (a, b) => {
            const nameA = a.data.title.toLowerCase()
            const nameB = b.data.title.toLowerCase()
            if (nameA > nameB) {
              return -1
            }
            if (nameA < nameB) {
              return 1
            }
            return 0
          }
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  created () {
    this.fetchList({ withCompletedWorks: true })
    if (this.list) {
      if (this.skip !== 0) {
        this.page = Math.round(this.skip / this.limit) + 1
      }
    }
    if (!this.categories) {
      this.fetchCategories()
    }
    if (!this.types) {
      this.fetchTypes()
    }
  },
  methods: {
    ...mapActions({
      fetchList: 'courses/GET_COURSES',
      fetchCategories: 'courses/COURSES_GET_CATEGORIES',
      fetchTypes: 'courses/COURSES_GET_TYPES'
    }),
    ...mapMutations({
      changeSkip: 'courses/COURSES_LIST_CHANGE_SKIP',
      changeStepLock: 'courses/COURSES_CHANGE_LOCK_STEP',
      changeFilter: 'courses/COURSES_FILTER_CHANGE'
    }),
    changeFilterLocal (value, type) {
      const obj = {
        type: type,
        value: value
      }
      this.changeFilter(obj)
      this.changeSkip(0)
      this.sendRequest()

      this.page = 1
    },
    changePagination (index) {
      this.changeSkip(this.limit * (index - 1))
      this.$vuetify.goTo(0)
      this.fetchList({ withCompletedWorks: true })
    },
    changeDialogDeleteItem (item) {
      this.showRemoveDialog = true
      this.removingItem = item
    },
    sendRequest: debounce(function () {
      this.fetchList({ withCompletedWorks: true })
    }, 1000),
    goCreatePage () {
      this.changeStepLock(true)
      this.$router.push({ name: 'courses.create' }).catch(() => console.log())
    }
  }
}
